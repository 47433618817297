import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
import 'amfe-flexible'
import 'vant/lib/index.css';
const app = createApp(App)
.use(router)
.use(vant)
.mount('#app');
