import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  //{path: '/',name: 'index',component: () => import('../views/index.vue')},
  {path: '/',name: 'login',component: () => import('../views/login.vue')},
  {path: '/getcode',name: 'getcode',component: () => import('../views/code.vue')},
  {path: '/ticket',name: 'ticket',component: () => import('../views/ticket.vue')},
  {path: '/blessing',name: 'blessing',component: () => import('../views/blessing.vue')},
  {path: '/vote',name: 'vote',component: () => import('../views/vote.vue')},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
   if (replaceWechatRedirectUri()) return false;
});


const getQueryVariable = function(value){
  var search = window.location.search;
  if(value == "state"){
    return search.substr(search.indexOf('state')+'state'.length+1,search.length);
  }else if(value == "code"){
    var code = search.indexOf("code=");
    var index = search.indexOf("&", code);
    return search.substring(index, code+"code=".length);
  }
}
/**
 * 处理微信授权回调redirect_uri
 */
 function replaceWechatRedirectUri(){
  const w = window.location.href.indexOf('?');
  const j = window.location.href.indexOf('#');
  if (w !== -1 && j > w && getQueryVariable('state')) {
    const state = getQueryVariable('state').split('#')[0];
    const redirect_path = JSON.parse(decodeURIComponent(state)).p;
    const url = window.location.origin + '/#' + redirect_path + `?code=${getQueryVariable('code')}&state=${state}`;
    location.replace(url);
    return true;
  }
  return false;
}
export default router
