<template>
  <transition name="van-slide-right">
    <router-view/>
  </transition>
</template>

<style>
html,body{
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#lang{
  position: fixed;
  top:28px;
  left:28px;
}

.lang-img{
  margin-top:30px;
  width:40px;
}

.nav{
  height: 100px;
  line-height: 100px;
  font-size: 32px;
  font-weight: 600;
  background-color: #fff;
}

.row-str{
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    color:#333333; 
    margin:30px 0;
}



.h1{
  color:#000;
  font-size:32px;
  font-weight: 600;
}
.h3{
  color:#333333;
  font-size: 22px;
}
.row{
  padding:30px 0;
  overflow-x: hidden;
}

.bottom-line{
  border-bottom:3px dashed #DBDBDB;
}

.bottom-line_solid{
  border-bottom:3px solid #DBDBDB;
}

.center{
  text-align: center;
}
</style>
